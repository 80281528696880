@if (task()) {
  <figure class="avt" [ngClass]="'avt--' + size()">
    @if (!hideStatus()) {
      <aw-task-status [status]="taskStatus()" [size]="size()" />
    }
    <figcaption class="avt__text" [class.avt__text--multiline]="multiline()">
      @if (project() && size() !== Size.xs && !hideProject()) {
        <aw-project-avatar [project]="project()" [size]="Size.xs" [link]="link()" />
      }

      @if (showText()) {
        <span
          class="avt__text__primary"
          [class.avt__text__link]="link()"
          [ngStyle]="{ '-webkit-line-clamp': lines() }"
          [attr.title]="task().name"
          (click)="linkClick($event)"
        >
          {{ task().name }}
        </span>
      }
    </figcaption>
  </figure>
}
