/* eslint-disable max-len */
import { Injectable } from '@angular/core'
import { DynamicRefService } from '../dynamic-ref-service/dynamic-ref.service'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { FilePreviewLightboxComponent } from '../../components/files/file-preview-lightbox/file-preview-lightbox.component'
import { FileDropOverlayComponent } from '../../components/files/file-drop-overlay/file-drop-overlay.component'
import { Observable, take } from 'rxjs'
import { ToastService } from '../toast-service/toast.service'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

/* eslint-enable max-len */

@Injectable({ providedIn: 'root' })
export class FileModalService {
  isOverlayShowing = false
  constructor(
    private fileService: FileService,
    private dynRefSvc: DynamicRefService,
    private toast: ToastService
  ) {
    // Subscriptions to show file upload's toasts
    this.fileService.fileUploading.subscribe(fileUpload => this.toast.showFileUploadProgress(fileUpload))
    this.fileService.fileUploadLimit.subscribe(() => this.toast.show(q.translations.errors.tooManyFileUploads))
  }

  /**
   * Shows the file drop overlay
   * @param {ValidFileEntities} entity
   * @param {ValidFileEntityTypes} entityType
   * @param {Element} inPlaceTarget
   */
  public showFileDropOverlay(
    entity: ValidFileEntities,
    entityType: ValidFileEntityTypes,
    inPlaceTarget?: HTMLElement
  ): void {
    if (this.isOverlayShowing) {
      return
    }

    this.isOverlayShowing = true

    const [overlayRef, overlay] = this.dynRefSvc.create(FileDropOverlayComponent)

    if (inPlaceTarget) {
      inPlaceTarget.appendChild(overlay.element.nativeElement)
      overlay.classes = 'in-place'
    }

    Object.assign(overlay, { entity, entityType, fileService: this.fileService })

    overlay.hiding.subscribe(() => {
      overlayRef.destroy()
      this.isOverlayShowing = false
    })
  }

  /**
   * Shows FilePreviewLightbox Overlay
   * @param {FileUpload} file
   * @param {ValidFileEntities} currentEntity
   * @param {ValidFileEntityTypes} currentEntityName
   * @param {boolean} showAddAttachmentButton
   * @returns {Observable<void>}
   */
  showFilePreviewLightboxOverlay(
    file: FileUpload,
    currentEntity: ValidFileEntities,
    currentEntityName: ValidFileEntityTypes,
    showAddAttachmentButton?: boolean
  ): Observable<void> {
    const [overlayRef, overlay] = this.dynRefSvc.create(FilePreviewLightboxComponent)
    overlay.file = file
    overlay.currentEntity = currentEntity
    overlay.currentEntityName = currentEntityName
    overlay.showAddAttachmentButton = showAddAttachmentButton

    const previewHiding$: Observable<void> = overlay.hiding.pipe(take(1))

    // Subscribes hiding event to destroy the component ref again
    previewHiding$.subscribe(() => overlayRef.destroy())

    // Returns hiding event to notify about preview close event
    return previewHiding$
  }
}
