import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  inject
} from '@angular/core'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { User } from '@awork/features/user/models/user.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { Subscription } from 'rxjs'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import replace from '@awork/_shared/functions/replace'
import { FileModalService } from '../../../services/file-modal-service/file-modal.service'
import { WithGlobals } from '../../../classes/with-globals'
import { Project } from '@awork/features/project/models/project.model'
import { Task } from '@awork/features/task/models/task.model'
import { RouterLink } from '@angular/router'
import { FileIconComponent } from '../../files/file-icon/file-icon.component'
import { NgClass, NgIf } from '@angular/common'
import { SharedModalService } from '../../../services/shared-modal-service/shared-modal.service'
import { MainSize, Size } from '@awork/_shared/types/size'
import { FabButtonComponent } from '../../icon-buttons/fab-button/fab-button.component'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { UserAvatarComponent } from '../../../../features/user/components/user-avatar/user-avatar.component'
import { DateTimeLabelComponent } from '../../data-display/date-time-label/date-time-label.component'
import { TaskAvatarComponent } from '../../../../features/task/components/task-avatar/task-avatar.component'
import { DotsLoaderComponent } from '../../ui-help/dots-loader/dots-loader.component'
import { FileSizePipe } from '../../../pipes/file-size/file-size.pipe'
import { Color } from '@awork/_shared/types/color'
import { ConfirmModalOptions } from '../../../services/shared-modal-service/types'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

@AutoUnsubscribe()
@Component({
  selector: 'aw-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FileIconComponent,
    TooltipDirective,
    UserAvatarComponent,
    DateTimeLabelComponent,
    RouterLink,
    FabButtonComponent,
    TaskAvatarComponent,
    DotsLoaderComponent,
    FileSizePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewComponent extends WithGlobals implements OnInit, OnChanges, OnDestroy {
  @Input() file: FileUpload
  @Input() entity: ValidFileEntities
  @Input() entityName: ValidFileEntityTypes
  @Input() showLoader = false
  @Input() showAddAttachmentButton: boolean
  @Input() size: MainSize = Size.s
  @Input() canEdit: boolean
  @Input() canManageProject: boolean
  @Input() canPlanProject: boolean
  @Input() showImageThumbnail: boolean = true
  @Input() showDescription: boolean = true
  @Input() showTinyDescription: boolean = false

  @ViewChild('downloadLink') downloadLinkElement: ElementRef

  sizes = Size
  protected readonly colors = Color
  isLoading = false
  translations = q.translations.FilePreviewComponent

  view: 'image' | 'icon' | 'externalFile' = 'image'

  uploader: User
  previewUrl: string
  supportsPreview: boolean
  showSmallDescription = false

  protected userSubscription: Subscription
  private fileModalService: FileModalService = inject(FileModalService)

  constructor(
    public element: ElementRef,
    private userQuery: UserQuery,
    private fileService: FileService,
    private sharedModalService: SharedModalService
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.file) {
      this.initUploader()
      this.previewUrl = this.file.mimeType?.includes('image') ? this.file.reducedImage : this.file.filePreview

      this.supportsPreview = this.file.isPreviewSupported()
      this.initView()
      this.showSmallDescription =
        !this.showTinyDescription && this.view !== 'image' && this.size == this.sizes.s && this.showDescription
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.file && changes.canManageProject) || changes.canPlanProject) {
      if (this.canManageProject && this.file.entityType === 'projects') {
        this.canEdit = this.canManageProject
      } else if (this.canPlanProject && this.file.entityType === 'tasks') {
        this.canEdit = this.canPlanProject
      }
    }
  }

  ngOnDestroy() {}

  /**
   * Inits the view according to the file type
   */
  private initView(): void {
    if (this.file.externalFileUrl) {
      this.view = 'externalFile'
      return
    }

    const isPreviewableImage =
      this.previewUrl && FileUpload.isImageMimeType(this.file?.mimeType) && this.file.isPreviewSupported()

    if (isPreviewableImage) {
      this.isLoading = this.showLoader
      this.view = 'image'
    } else {
      this.view = 'icon'
    }
  }

  /**
   * Inits the user that has uploaded the file
   */
  private initUploader(): void {
    if (User.isAworkGenericUser(this.file.createdBy)) {
      this.uploader = User.getAworkUser('file-preview')
    } else {
      this.userSubscription = this.userQuery.selectUser(this.file.createdBy).subscribe(user => {
        if (user) {
          this.uploader = user
        } else if (['projects', 'tasks'].includes(this.file.entityType)) {
          const project =
            this.file.entityType === 'projects' ? (this.entity as Project) : (this.entity as Task)?.project

          const projectMember = project?.members?.find(member => member.userId === this.file.createdBy)
          this.uploader = projectMember?.user
        }
      })
    }
  }

  /**
   * Show Image after loading successfully and hide file Icon
   */
  protected imageLoaded(): void {
    this.isLoading = false
  }

  /**
   * Handle broken images and show file Icon
   */
  protected imageLoadingError(): void {
    this.isLoading = false
    this.view = 'icon'
  }

  /**
   * Opens the carousel with the current selected file
   */
  protected onView(): void {
    this.fileModalService.showFilePreviewLightboxOverlay(
      this.file,
      this.entity,
      this.entityName,
      this.showAddAttachmentButton
    )
  }

  /**
   * Handles the file deletion
   */
  protected onDelete(): void {
    const provider = this.file.externalProvider

    const confirmOptions: ConfirmModalOptions = {
      title: provider ? this.translations.removeFileTitle : this.translations.deleteFileTitle,
      subtitle: provider
        ? replace(this.translations.removeFileSubtitle, { fileName: this.file.name })
        : replace(this.translations.deleteFileSubtitle, { fileName: this.file.name }),
      actionText: provider ? this.translations.removeFileButtonText : q.translations.common.delete,
      actionColor: Color.Red,
      loadingObservable: null,
      delayHide: true
    }

    this.sharedModalService.showConfirm(confirmOptions).confirm.subscribe(() => {
      this.fileService.deleteEntityFile(this.file.entityType, this.file.entityId, this.file).subscribe()
    })
  }
}
