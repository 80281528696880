import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { TaskStatus } from '@awork/features/task/models/task-status.model'
import { Size } from '@awork/_shared/types/size'
import { NgClass, NgIf } from '@angular/common'

type TaskStatusSize = Size.xs | Size.s | Size.m

@Component({
  selector: 'aw-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass]
})
export class TaskStatusComponent {
  @Input() status: TaskStatus
  @Input() size: TaskStatusSize = Size.xs
}
