import { NgClass, NgIf } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { Subscription, debounceTime, filter, fromEvent, map } from 'rxjs'
import { WithGlobals } from '../../../classes/with-globals'
import { AutoFocusDirective } from '../../../directives/auto-focus/auto-focus.directive'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { RemoveButtonComponent } from '../../icon-buttons/remove-button/remove-button.component'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { FilterButtonComponent } from '../../icon-buttons/filter-button/filter-button.component'

@AutoUnsubscribe()
@Component({
  selector: 'aw-inline-search-field',
  styleUrls: ['../styles.scss', './inline-search-field.component.scss'],
  templateUrl: './inline-search-field.component.html',
  standalone: true,
  imports: [NgIf, NgClass, AutoFocusDirective, RemoveButtonComponent, FilterButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineSearchFieldComponent extends WithGlobals implements AfterViewInit, OnChanges, OnDestroy {
  @Input() placeholder = `${q.translations.common.search}...`
  @Input() minLength = 3
  @Input() autoFocus = false
  @Input() showLeftIcon = true
  @Input() size: ButtonSize = Size.m
  @Input() disabled = false
  @Input() showFilterButton: boolean
  @Input() hasActiveFilters: boolean

  @Input() set value(value: string) {
    this.input.nativeElement.value = value
    this.currentValue = value
  }

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>()
  @Output() onEnterPressed: EventEmitter<string> = new EventEmitter<string>()
  @Output() onEscPressed: EventEmitter<string> = new EventEmitter<string>()
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() onFilterButtonClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()

  @ViewChild('input') input: ElementRef<HTMLInputElement>
  currentValue = ''

  inputSubscription: Subscription

  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngAfterViewInit(): void {
    this.setSearchEvent()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoFocus && !changes.autoFocus.firstChange) {
      this.input.nativeElement.focus()
    }
  }

  ngOnDestroy(): void {}

  /**
   * Emits the onSearch event when the user stops typing (500ms),
   * the input is at least minLength long and the input is different
   * than the last one.
   */
  private setSearchEvent(): void {
    this.inputSubscription = fromEvent(this.input.nativeElement, 'input')
      .pipe(
        debounceTime(500),
        map((event: Event) => (event.target as HTMLInputElement).value),
        filter(value => {
          return (value.length >= this.minLength || value === '') && this.currentValue !== value
        })
      )
      .subscribe((value: string) => {
        this.onSearch.emit(value)
        this.currentValue = value
      })
  }

  /**
   * Clears the field and emits the onSearch event
   * @param {boolean} shouldFocus
   */
  clear(shouldFocus = true): void {
    this.input.nativeElement.value = ''

    if (this.currentValue !== '') {
      this.onSearch.emit('')
      this.currentValue = ''
    }

    if (shouldFocus) {
      this.focus()
    }
  }

  /**
   * Focus the search input
   * @param {boolean} select
   */
  focus(select = true): void {
    this.input?.nativeElement?.focus()

    if (select) {
      this.input?.nativeElement?.select()
    }
  }

  /**
   * Emits the click event of the filter button
   * @param {MouseEvent} event
   */
  filterButtonClicked(event: MouseEvent): void {
    this.onFilterButtonClicked.emit(event)
  }

  /**
   * Marks the component for check when the input value changes
   * otherwise there is a delay for the buttons to appear
   */
  onInputChanged(): void {
    this.cdr.markForCheck()
  }
}
