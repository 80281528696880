import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { Color } from '@awork/_shared/types/color'
import { NgClass } from '@angular/common'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
  standalone: true,
  imports: [NgClass, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FabButtonComponent implements OnChanges, OnInit {
  @Input({ required: true }) icon: string
  @Input() tooltip: string
  @Input() color: Color.Blue | Color.White | Color.Red = Color.White
  @Input() size: ButtonSize = Size.m
  @Input() tooltipOnTop: boolean
  @Input() suppressClick = false

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.suppressClick) {
      event.preventDefault()

      if (this.disabled) {
        event.stopPropagation()
      }
    }
  }

  iconButtonClasses: string[]
  elementRef: ElementRef = inject(ElementRef)

  ngOnInit() {
    this.iconButtonClasses = this.getIconButtonClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.color && !changes.color.isFirstChange()) || (changes.size && !changes.size.isFirstChange())) {
      this.iconButtonClasses = this.getIconButtonClasses()
    }
  }

  /**
   * Initializes all classes used to style the icon button.
   * @returns {string[]} The classes used to style the icon button.
   */
  getIconButtonClasses(): string[] {
    return [`btn-icon--${this.color}`, `btn-icon--${this.size}`]
  }
}
