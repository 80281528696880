<section
  class="file-drop"
  [class.file-drop--highlight]="isDraggingOver"
  (dragover)="$event.preventDefault(); isDraggingOver = true"
  (dragleave)="isDraggingOver = false"
  (drop)="fileDropped($event)"
>
  <article class="file-drop__content">
    <span class="file-drop__content__img">{{ emoji }}</span>

    <p
      class="aw-2-mt--m aw-2-txt--h3"
      [ngClass]="isDraggingOver ? 'aw-2-txt--blue' : 'aw-2-txt--night'"
    >
      <ng-content select="[title]" />
    </p>

    <p
      class="aw-2-mt--s aw-2-txt--body-small aw-2-txt--bold"
      [ngClass]="isDraggingOver ? 'aw-2-txt--blue' : 'aw-2-txt--steel'"
    >
      <ng-content select="[subtitle]" />
    </p>

    <p
      *ngIf="uploadError"
      class="aw-2-mt--m aw-2-txt--body-small aw-2-txt--red"
    >
      {{ uploadError }}
    </p>
  </article>
</section>
