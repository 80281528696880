import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import { WithGlobals } from '../../../classes/with-globals'
import { FileDropComponent } from '../file-drop/file-drop.component'
import { TruncatePipe } from '../../../pipes/truncate/truncate.pipe'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

@Component({
  selector: 'aw-entity-file-drop',
  templateUrl: './entity-file-drop.component.html',
  styleUrls: ['./entity-file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileDropComponent, TruncatePipe]
})
export class EntityFileDropComponent extends WithGlobals {
  @Input() entity: ValidFileEntities
  @Input() entityType: ValidFileEntityTypes
  @Input() fileService: FileService

  @Output() onFilesDropped = new EventEmitter<void>()

  attachmentFiles: File[] = []
  uploadError = ''
  isDraggingOver = false

  constructor() {
    super()
  }

  /**
   * Callback triggered when the file is dropped on the overlay
   * @param {File[]} files
   */
  filesDropped(files: File[]): void {
    files?.forEach(attachment => {
      this.fileService.sendEntityFile(this.entityType, this.entity, attachment).subscribe()
    })

    this.onFilesDropped.emit()
  }
}
