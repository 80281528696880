import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core'
import { NgClass } from '@angular/common'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import { Subject, distinctUntilChanged, throttleTime } from 'rxjs'
import { OverlayComponent } from '../../layout/overlay/overlay.component'
import { EntityFileDropComponent } from '../entity-file-drop/entity-file-drop.component'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

@Component({
  selector: 'aw-file-drop-overlay',
  templateUrl: './file-drop-overlay.component.html',
  styleUrls: ['./file-drop-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, EntityFileDropComponent, OverlayComponent]
})
export class FileDropOverlayComponent implements OnInit, AfterViewInit {
  @Input() entity: ValidFileEntities
  @Input() entityType: ValidFileEntityTypes

  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('overlay') overlay: OverlayComponent

  dragOver$: Subject<boolean> = new Subject<boolean>()
  private destroyRef = inject(DestroyRef)

  fileService: FileService
  classes = ''

  constructor(
    public element: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dragOver$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        throttleTime(50, undefined, { leading: true, trailing: true })
      )
      .subscribe(isDraggingOver => {
        if (!isDraggingOver) {
          this.hide()
        }
      })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.overlay && this.overlay.show) {
        this.show()
      }
    })
  }

  /**
   * Shows the overlay
   */
  show(): void {
    this.overlay.show()
    this.cdr.markForCheck()
  }

  /**
   * Hides the overlay
   */
  hide(): void {
    this.overlay.hide()
    this.cdr.markForCheck()
  }

  /**
   * Callback triggered when the file is leaving the overlay
   * @param {DragEvent} event
   */
  dragLeave(event: DragEvent): void {
    event.stopPropagation()
    event.preventDefault()

    setTimeout(() => {
      this.dragOver$.next(false)
    }, 200)
  }

  /**
   * Callback triggered when the file is dragged over the overlay
   * @param {DragEvent} event
   */
  dragOver(event: DragEvent): void {
    event.stopPropagation()
    event.preventDefault()

    this.dragOver$.next(true)
  }
}
