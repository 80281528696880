import { Injectable } from '@angular/core'
import { PopupComponent } from './popup.component'

@Injectable({ providedIn: 'root' })
export class PopupService {
  popups: Array<PopupComponent> = [] // List of active modals

  /**
   * Adds popup to the popups array
   * @param newPopup
   */
  addPopup(newPopup: PopupComponent): void {
    this.popups.push(newPopup)
  }

  /**
   * Removes a popup from the popups array
   * @param popupToClose
   */
  removePopup(popupToClose: PopupComponent): void {
    const popupIndex = this.popups.findIndex(popup => popup === popupToClose)

    this.popups.splice(popupIndex, 1)
  }

  /**
   * Returns true if popup is the last component from the popups array
   * @param popup
   * @returns boolean
   */
  isPopupLast(popup: PopupComponent): boolean {
    return this.popups.slice(-1)[0] === popup
  }
}
