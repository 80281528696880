<aw-modal
  #modal
  [size]="Size.s"
  [showCloseButton]="true"
  [title]="title"
  (hiding)="onCancel()"
>
  <div>
    <aw-info-box
      *ngIf="highlightedText"
      class="aw-2-mb--s"
      [text]="highlightedText"
      [color]="actionColor"
      [centerText]="true"
      [width]="InfoBoxWidth.Stretch"
    />
    <p *ngIf="subtitle" class="subtitle">{{ subtitle }}</p>
  </div>

  <ng-content />

  <div modal-footer class="footer">
    <aw-button
      [type]="'tertiary'"
      [size]="Size.m"
      [text]="cancelText"
      (onClick)="onCancel()"
    />
    <aw-button
      [color]="actionColor"
      [type]="'primary'"
      [size]="Size.m"
      [text]="actionText"
      [loadingText]="loadingText"
      [state]="buttonState()"
      (onClick)="onConfirm()"
    />
  </div>
</aw-modal>
