import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import getLanguage from '@awork/_shared/functions/get-language'
import { isValid } from '@awork/_shared/functions/date-fns-wrappers'
import { getLocalDateFromUTCString } from '@awork/_shared/functions/date-operations'
import { DateTimePipe } from '@awork/_shared/pipes/date-time.pipe'
import { NgIf, DatePipe } from '@angular/common'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-date-time-label',
  templateUrl: './date-time-label.component.html',
  styleUrls: ['./date-time-label.component.scss'],
  standalone: true,
  imports: [NgIf, TooltipDirective, DatePipe, DateTimePipe]
})
export class DateTimeLabelComponent implements OnChanges {
  @Input({ required: true }) date: string | Date
  @Input() showYear = true
  @Input() showTimeInTooltip = false
  @Input() short = false

  lang = getLanguage()
  relativeDateReference = new Date()

  isValidParsedDate: boolean
  parsedDate: Date

  private readonly now = new Date(this.relativeDateReference.getTime())

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date || changes.showTimeInTooltip) {
      this.setRelativeDateReferenceTime()

      const { isValid, date } = this.getDateTime()
      this.isValidParsedDate = isValid
      this.parsedDate = date
    }
  }

  /**
   * Sets the comparison hour depending on whether we display the time in the tooltip or not
   */
  private setRelativeDateReferenceTime(): void {
    if (!this.showTimeInTooltip) {
      this.relativeDateReference.setHours(0, 0, 0, 0)
    } else {
      this.relativeDateReference.setHours(
        this.now.getHours(),
        this.now.getMinutes(),
        this.now.getSeconds(),
        this.now.getMilliseconds()
      )
    }
  }

  /**
   * Returns a parsed date along with its validity
   * @returns {{ isValid: boolean, date: Date }}
   */
  private getDateTime(): { isValid: boolean; date: Date } {
    const parsedDate = this.showTimeInTooltip ? new Date(this.date) : getLocalDateFromUTCString(this.date)
    const isValidParsedDate = this.isValidDate(parsedDate)

    return {
      isValid: isValidParsedDate,
      date: isValidParsedDate ? new Date(parsedDate) : parsedDate
    }
  }

  /**
   * Checks if a date is valid
   * @param {date}
   * @returns {boolean}
   */
  private isValidDate(date: Date): boolean {
    try {
      return isValid(date)
    } catch (_) {
      return false
    }
  }
}
