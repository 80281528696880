<button 
  class="btn-icon" 
  type="button"
  [ngClass]="iconButtonClasses"
  [awTooltip]="tooltip"
  [actionTooltip]="true"
  [fromTop]="tooltipOnTop"
>
  <i class="aw-2-icn">{{ icon }}</i>
</button>
