<!-- Loader -->
<div *ngIf="isLoading" class="aw-2-pos--center-all">
  <aw-dots-loader
    [size]="sizes.l"
    [color]="colors.Fog"
  />
</div>

<!-- File icon -->
<div *ngIf="view === 'icon'" class="aw-2-pos--center-all">
  <aw-file-icon
    class="aw-2-m--xl"
    [extension]="file.fileExtension"
    [size]="sizes.xl"
  />
</div>

<!-- Image thumbnail -->
<img
  *ngIf="view === 'image'"
  [hidden]="isLoading"
  [src]="file.reducedImage"
  (load)="imageLoaded()"
  (error)="imageLoadingError()"
/>
