import { ChangeDetectionStrategy, Component, computed, inject, Injector, input } from '@angular/core'
import { Size } from '@awork/_shared/types/size'
import { Task } from '@awork/features/task/models/task.model'
import { ProjectAvatarComponent } from '../../../project/components/project-avatar/project-avatar.component'
import { NgClass, NgStyle } from '@angular/common'
import { TaskStatusComponent } from '../task-status/task-status.component'
import { Router } from '@angular/router'
import { AvatarPixelSizes } from '../../../../_shared/types/avatar-sizes'
import { getTaskDetailNavigationRoute } from '@awork/_shared/functions/task-detail-navigation'
import { TaskTemplate } from '@awork/features/task/models/task-template.model'
import type { TaskStatus } from '@awork/features/task/models/task-status.model'
import type { Project } from '@awork/features/project/models/project.model'
import type { IWorkloadTaskDetails } from '../../../planner/models/workload.model'

export type TaskAvatarSize = Size.xs | Size.s | Size.m

@Component({
  selector: 'aw-task-avatar',
  templateUrl: './task-avatar.component.html',
  styleUrls: ['./task-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, ProjectAvatarComponent, TaskStatusComponent]
})
export class TaskAvatarComponent {
  private router = inject(Router)
  private injector = inject(Injector)

  task = input.required<Task | TaskTemplate | IWorkloadTaskDetails>()
  size = input<TaskAvatarSize>(Size.m)
  hideProject = input(false)
  hideStatus = input(false)
  link = input(false)
  multiline = input(false)
  lines = input(2)
  showText = input(true)

  protected readonly Size = Size
  protected readonly AvatarPixelSizes = AvatarPixelSizes

  taskStatus = computed(() => this.getTaskStatus())
  project = computed(() => this.getProject())

  /**
   * Gets the task status
   * @returns {TaskStatus}
   */
  private getTaskStatus(): TaskStatus {
    const task = this.task()
    return !(task instanceof TaskTemplate) ? task.taskStatus : undefined
  }

  /**
   * Gets the project
   * @returns {Project}
   */
  private getProject(): Project {
    const task = this.task()
    return task instanceof Task ? task.project : undefined
  }

  /**
   * Navigates to task details
   */
  linkClick(event: MouseEvent | TouchEvent) {
    if (this.link()) {
      event.stopPropagation()

      const taskRoute = getTaskDetailNavigationRoute(this.task(), this.injector)
      this.router.navigate(taskRoute, {})
    }
  }
}
