import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { NgIf } from '@angular/common'
import { Color } from '@awork/_shared/types/color'
import { DotsLoaderComponent } from '../../../ui-help/dots-loader/dots-loader.component'
import { FileIconComponent } from '../../file-icon/file-icon.component'
import { Size } from '@awork/_shared/types/size'

@Component({
  selector: 'aw-file-preview-lightbox-thumbnail',
  templateUrl: './file-preview-lightbox-thumbnail.component.html',
  styleUrls: ['./file-preview-lightbox-thumbnail.component.scss'],
  standalone: true,
  imports: [NgIf, DotsLoaderComponent, FileIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewLightboxThumbnailComponent implements OnInit, OnChanges {
  @Input() file: FileUpload

  isLoading = false
  colors = Color
  sizes = Size
  view: 'image' | 'icon' = 'icon'

  ngOnInit(): void {
    this.initImage()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file?.previousValue !== changes.file?.currentValue) {
      this.initImage()
    }
  }

  /**
   * Inits the image view
   */
  initImage(): void {
    if (this.file?.reducedImage && this.file?.mimeType && FileUpload.isImageMimeType(this.file?.mimeType)) {
      this.isLoading = true

      this.view = 'image'
    } else {
      this.view = 'icon'
    }
  }

  /**
   * Show Image after loading successfully and hide file Icon
   */
  imageLoaded(): void {
    this.isLoading = false
  }

  /**
   * Handle broken images and show file Icon
   */
  imageLoadingError(): void {
    this.isLoading = false
    this.view = 'icon'
  }
}
