import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {
  /**
   * Truncates a string with JS and appends three dots at the end if longer.
   * Default length is 50 characters.
   * @param string
   * @param maxLength
   */
  transform(string: string, maxLength = 50): string {
    return string && string.length > maxLength ? string.substr(0, maxLength) + '...' : string
  }
}
