import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { ToastService } from '../../../services/toast-service/toast.service'
import { WithGlobals } from '../../../classes/with-globals'
import { TruncatePipe } from '../../../pipes/truncate/truncate.pipe'

@Component({
  selector: 'aw-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, TruncatePipe]
})
export class FileDropComponent extends WithGlobals {
  @Input() emoji = '🫳'

  @Output() onFilesDropped = new EventEmitter<File[]>()

  protected readonly translations = q.translations.FileDropComponent

  attachmentFiles: File[] = []
  uploadError = ''
  isDraggingOver = false

  constructor(private toastService: ToastService) {
    super()
  }

  /**
   * Callback triggered when the file is dropped on the overlay
   * @param {DragEvent} event
   */
  protected fileDropped(event: DragEvent): void {
    event.stopPropagation()
    event.preventDefault()

    this.isDraggingOver = false
    this.attachmentFiles = Array.from(event.dataTransfer.files)

    const hasEmptyFiles = this.attachmentFiles?.some(file => !file.size)

    if (this.attachmentFiles.length && !hasEmptyFiles) {
      this.onFilesDropped.emit(this.attachmentFiles)
    } else {
      this.toastService.show(q.translations.FileContextMenu.fileWithNoSize, { type: 'error' })
      this.onFilesDropped.emit(null)
    }
  }

  /**
   * Handles the dragover event on the dropzone
   * @param {DragEvent} event
   */
  protected onDragOver(event: DragEvent) {
    event.stopPropagation()
    event.preventDefault()

    this.isDraggingOver = true
  }
}
