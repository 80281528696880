import { Injectable } from '@angular/core'
import { ConfirmationModalComponent } from '../../components/misc/confirmation-modal/confirmation-modal.component'
import { DynamicRefService } from '../dynamic-ref-service/dynamic-ref.service'
import { ConfirmModalOptions } from './types'

@Injectable({
  providedIn: 'root'
})
export class SharedModalService {
  constructor(private dynamicRefService: DynamicRefService) {}

  /**
   * Show the confirmation modal component
   * @param {ConfirmModalOptions} options
   * @returns {ConfirmationModalComponent}
   */
  showConfirm(options: ConfirmModalOptions): ConfirmationModalComponent {
    const [ref, modal] = this.dynamicRefService.create(ConfirmationModalComponent)

    Object.assign(modal, options)

    modal.hiding.subscribe(() => ref.destroy())

    return modal
  }
}
