<div
  *ngIf="visible"
  class="ly-overlay"
  [class.ly-overlay--behind-modals]="isBehindModals"
  [class.ly-overlay--solid]="solid"
  [@overlay]="visible"
>

  <aw-close-button
    *ngIf="showCloseButton"
    class="ly-overlay__close"
    [size]="sizes.m"
    (click)="hide()"
  />

  <!-- Content Outlet -->
  <ng-content />
</div>
