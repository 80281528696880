<button
  #trigger
  class="btn-icon"
  type="button"
  [ngClass]="filterButtonClasses"
  [awTooltip]="tooltip"
  [actionTooltip]="true"
  (click)="onClick($event)"
>
  <i class="aw-2-icn">filter_alt</i>
</button>

<aw-popup
  #popup
  [origin]="trigger"
  [widthType]="'block'"
  [position]="popupPosition"
>
  <ng-content />
</aw-popup>
