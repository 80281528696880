<aw-overlay #overlay [blur]="false" [solid]="true" (hiding)="this.hiding.emit()">
  <article class="overlay">
    <!-- Header -->
    <section class="overlay__header aw-2-mt--xl">
      <h3 class="aw-2-txt--night aw-2-txt--truncate">
        {{ selectedIndex >= 0 && file?.name ? file.name : 'Select an image to preview' }}
      </h3>

      <p *ngIf="selectedIndex >= 0 && file" class="aw-2-mt--s aw-2-txt--steel">
        {{ q.translations.ActivityLogComponent.activityList.attachments.uploadedBy }}: {{ file.username }}
        {{ q.translations.common.at }} {{ file.createdOn | date }} - {{ file.size | fileSize }}
      </p>
    </section>

    <!-- Preview -->
    <section class="overlay__body aw-2-flx--flex-1 aw-2-flx aw-2-flx--column aw-2-flx--center">
      <article class="overlay__body__wrapper aw-2-flx aw-2-flx--center aw-2-flx--flex-1">
        <!-- Button left -->
        <div class="overlay__body__wrapper--controls--left">
          <aw-icon-button
            [disabled]="selectedIndex === 0"
            [size]="sizes.m"
            [icon]="'keyboard_arrow_left'"
            [tooltip]="q.translations.common.previous"
            (click)="prev()"
          />
        </div>

        <!-- Preview Image -->
        <div class="aw-2-flx--flex-1 overlay__body__wrapper__preview">
          <aw-file-preview-lightbox-thumbnail *ngIf="file?.isImage()" [file]="file" />

          <div *ngIf="file?.isVideo()">
            <video
              #videoPreview
              controls
              class="overlay__body__wrapper__preview--video"
              [hidden]="videoLoading"
              (loadeddata)="onVideoLoadedData()"
              (loadstart)="onVideoLoadStart()"
            >
              <source [src]="file.downloadUrl" [type]="getVideoMimeType(file)" />
            </video>

            <div *ngIf="videoLoading" class="aw-2-pos--center-all">
              <aw-dots-loader [size]="sizes.l" [color]="colors.Fog" />
            </div>
          </div>

          <div *ngIf="file?.isPDFPreviewSupported()" class="overlay__body__wrapper__preview--file">
            <div *ngIf="!hasPDFPreviewError && !pdfPreview?.file" class="aw-2-pos--center-all">
              <aw-dots-loader [size]="sizes.l" [color]="colors.Fog" />
            </div>

            <iframe
              *ngIf="pdfPreview?.id === file?.id && pdfPreview?.file"
              class="overlay__body__wrapper__preview--file--pdf"
              [src]="pdfPreview?.file"
            >
            </iframe>

            <div *ngIf="hasPDFPreviewError" class="aw-2-pos--center-all">
              <i class="aw-2-mt--xl">🙈</i>
              <h2>{{ translations.pdfPreviewError }}</h2>
            </div>
          </div>
        </div>

        <!-- Button right -->
        <div class="overlay__body__wrapper--controls--right">
          <aw-icon-button
            [size]="sizes.m"
            [icon]="'keyboard_arrow_right'"
            [tooltip]="q.translations.common.next"
            [disabled]="selectedIndex === sliderArray?.length - 1"
            (click)="next()"
          />
        </div>
      </article>
    </section>

    <!-- Footer -->
    <section #thumbnails class="overlay__footer">
      <article class="overlay__footer__actions">
        <a #downloadLink [href]="file?.downloadUrl">
          <aw-fab-button
            [icon]="'file_download'"
            [size]="sizes.m"
            [tooltip]="q.translations.common.download"
            [suppressClick]="true"
          />
        </a>

        <!-- Delete -->
        <aw-fab-button
          *ngIf="canEdit"
          class="aw-2-btn--clear"
          icon="delete"
          [size]="sizes.m"
          [tooltip]="q.translations.common.delete"
          [color]="colors.Red"
          (click)="deleteAttachmentFile(file)"
        />
      </article>

      <!-- Current index -->
      <p class="aw-2-txt--steel aw-2-p--m aw-2-txt--bold">{{ selectedIndex + 1 }} / {{ sliderArray?.length }}</p>

      <article #thumbnailsDetails class="overlay__footer__thumbnails">
        <aw-file-preview
          *ngFor="let item of sliderArray; let i = index"
          [file]="item"
          [showAddAttachmentButton]="showAddAttachmentButton"
          [size]="sizes.s"
          [showTinyDescription]="true"
          (click)="selectThumbnail(i)"
        />

        <!-- Add button -->
        <div
          *ngIf="canEdit && showAddAttachmentButton"
          class="overlay__footer__thumbnails--add-new-file aw-2-px--m aw-2-pos--relative"
          (click)="uploadElement.click()"
        >
          <span class="aw-2-pos--center-all">
            <i class="aw-2-icn">add_circle_outline</i>
          </span>

          <!-- Input for newly uploaded files -->
          <input
            #uploadElement
            id="uploadElement"
            class="👻"
            type="file"
            [multiple]="true"
            (change)="addNewFile($event)"
          />
        </div>
      </article>
    </section>
  </article>
</aw-overlay>
