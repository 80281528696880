import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe, registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'
import localeEn from '@angular/common/locales/en'
import getLanguage from '@awork/_shared/functions/get-language'
import { differenceInCalendarDays } from '@awork/_shared/functions/date-fns-wrappers'
import { DateCategories, getDateCategory } from '@awork/_shared/functions/date-operations'

registerLocaleData(localeDe)
registerLocaleData(localeEn)

@Pipe({
  name: 'dateTime',
  standalone: true
})
export class DateTimePipe implements PipeTransform {
  translations = q.translations.dates

  transform(value: Date, showYear: boolean = true, short: boolean = false, now = new Date()): string {
    if (!value) {
      return ''
    }

    const dateCategory = getDateCategory(value, now)
    value = value instanceof Date ? value : new Date(value)

    const diffToNow = (now.getTime() - value.getTime()) / 1000

    switch (dateCategory) {
      case DateCategories.Now:
        return this.translations.now
      case DateCategories.WithinLastHour:
        return this.translations.minsAgo.replace('{{x}}', `${Math.floor(diffToNow / 60)}`)
      case DateCategories.Today:
        return short ? this.translations.tdy : this.translations.today
      case DateCategories.Yesterday:
        return short ? this.translations.ystd : this.translations.yesterday
      case DateCategories.LastWeek:
        return this.translations.lastWeek
      case DateCategories.FiveDaysAgo:
        const daysAgo = differenceInCalendarDays(now, value)

        return short ? `-${daysAgo}d` : this.translations.daysAgo.replace('{{x}}', `${daysAgo}`)
      case DateCategories.WithinAnHour:
        return this.translations.inMins.replace('{{x}}', `${Math.ceil(Math.abs(diffToNow) / 60)}`)
      case DateCategories.Tomorrow:
        return short ? this.translations.tmrw : this.translations.tomorrow
      case DateCategories.InFiveDays:
        const inDays = differenceInCalendarDays(value, now)

        return short ? `${inDays}d` : this.translations.inDays.replace('{{x}}', `${inDays}`)
      case DateCategories.None:
        const pipe = new DatePipe(getLanguage())
        if (value.getFullYear() === new Date().getFullYear() || showYear === false) {
          return pipe.transform(value, 'dd. MMM')
        } else {
          return pipe.transform(value, `dd. MMM 'yy`)
        }
    }
  }
}
