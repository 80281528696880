import { TaskListTemplate } from '@awork/features/task/models/task-list-template.model'
import { WorkType } from '@awork/features/task/models/work-type.model'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { User } from '@awork/features/user/models/user.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { cloneDeep } from '@awork/_shared/functions/lodash'
import { Tag } from '@awork/_shared/models/tag.model'
import { CustomField } from '@awork/features/project/models/custom-field.model'

/**
 * Task Bundle
 */
export class TaskTemplate {
  id: string
  name: string
  description: string
  isPrio: boolean
  plannedDuration: number
  relativeStartOn: number
  relativeDueOn: number
  typeOfWorkId: string
  typeOfWork: WorkType
  taskListTemplate: TaskListTemplate[] = []
  attachments: FileUpload[] = []
  assigneeIds: string[] = []
  assignedRoleId: string
  isRecurring: boolean
  parentId?: string
  subtaskOrder?: number
  tags: Tag[]
  customFields?: CustomField[]
  projectTemplateId?: string

  constructor(taskList: any) {
    Object.assign(this, cloneDeep(taskList))
  }

  get lists(): TaskListTemplate[] {
    return this.taskListTemplate
  }

  set lists(lists: TaskListTemplate[]) {
    this.taskListTemplate = lists
  }

  get order(): number {
    if (this.taskListTemplate && this.taskListTemplate.length > 0) {
      return this.taskListTemplate[0].orderOfTask
    } else {
      return 0
    }
  }

  get isPrivate(): boolean {
    return false
  }

  set order(newOrder: number) {
    if (this.taskListTemplate && this.taskListTemplate.length > 0) {
      this.taskListTemplate[0].orderOfTask = newOrder
    }
  }

  get assignees(): User[] {
    return UserQuery?.instance?.getUsersByIds(this.assigneeIds) || []
  }

  set assignees(users: User[]) {
    this.assigneeIds = users.length ? users.map(({ id }) => id) : []
  }

  /**
   * Gets whether or not task has assignees
   * @returns {boolean}
   */
  get hasAssignees(): boolean {
    return !!this.assigneeIds?.length
  }

  /**
   * Gets whether the relative due on property is set
   * @returns {boolean}
   */
  get isRelativeDueOnSet(): boolean {
    return typeof this.relativeDueOn === 'number'
  }

  /**
   * compares a group of assignee with task's assignees
   * @param {string[]} assigneeIdsToCompare
   * @returns {boolean}
   */
  hasSameAssignees(assigneeIdsToCompare: string[]): boolean {
    return this.assigneeIds.every(userId => assigneeIdsToCompare.includes(userId))
  }

  /**
   * Gives the order of the task within a list
   * @param {TaskList} list
   * @returns {number} - the order of the task in the list
   */
  getOrderForTaskList(list: TaskListTemplate): number {
    if (this.taskListTemplate?.length > 0 && list) {
      const foundList = this.lists.find(l => l.id === list.id)
      return foundList ? foundList.orderOfTask : 0
    } else {
      return 0
    }
  }
}
