import {
  AfterViewInit,
  ContentChildren,
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  untracked
} from '@angular/core'
import { NavListService } from './nav-list.service'
import { NavListItemDirective } from './directives/nav-list-item/nav-list-item.directive'
import { FocusMonitor } from '@angular/cdk/a11y'

@Directive({
  selector: '[awNavList]',
  standalone: true,
  providers: [NavListService]
})
export class NavListDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() isNavListVisible = true
  @Input() autofocusNavList = true

  @Output() selectedItemIndex: EventEmitter<number> = new EventEmitter<number>()
  @Output() selectedItemValue: EventEmitter<string> = new EventEmitter<string>()
  @Output() keyboardSelectedIndex: EventEmitter<number> = new EventEmitter<number>()

  @ContentChildren(NavListItemDirective, { descendants: true }) navListItems: QueryList<NavListItemDirective>

  selectedIndex: number

  private keyboardListener: (event: KeyboardEvent) => void

  constructor(
    private navListService: NavListService,
    public elementRef: ElementRef,
    private renderer: Renderer2,
    private _focusMonitor: FocusMonitor
  ) {
    effect(() => this.handleSelectedItem())
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isNavListVisible?.currentValue) {
      this.init()
    } else {
      this.selectedIndex = -1
      this.navListService.removeFocus()
    }
  }

  ngAfterViewInit() {
    this.addKeyboardListener()

    if (this.isNavListVisible) {
      this.init()
    }
  }

  ngOnDestroy() {
    this.elementRef?.nativeElement?.blur()
    this.elementRef?.nativeElement?.removeEventListener('keydown', this.keyboardListener)

    this.navListService.removeFocus()
    this.navListService.removeSelection()
  }

  /**
   * Add a listener to the keyboard events of the list container
   * @private
   */
  private addKeyboardListener(): void {
    this.keyboardListener = (event: KeyboardEvent) => this.itemKeySelection(event)
    this.elementRef.nativeElement.addEventListener('keydown', this.keyboardListener)
  }

  /**
   * Emits when an item is selected via keyboard or click
   * @private
   */
  private handleSelectedItem(): void {
    const selectedItem = this.navListService.selectedItem()

    if (selectedItem) {
      this.selectedIndex = this.navListItems.toArray().findIndex(item => item.itemId === selectedItem.itemId)

      // untracked is needed to prevent error NG0602: https://angular.io/errors/NG0602#tosignal
      untracked(() => {
        this.selectedItemIndex.emit(this.selectedIndex)
        this.selectedItemValue.emit(selectedItem.value)
      })
    }
  }

  /**
   * Sets the focus on the first list container
   */
  init(): void {
    setTimeout(() => {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', '0')

      if (this.autofocusNavList) {
        this._focusMonitor.focusVia(this.elementRef, 'program', { preventScroll: true })
      }
    })
  }

  /**
   * Selects the item according to the keys pressed (navigation)
   * @param {KeyboardEvent} event
   */
  private itemKeySelection(event: KeyboardEvent): void {
    if (!this.navListItems?.length) {
      return
    }

    const itemCount = this.navListItems.length

    switch (event.code) {
      case 'ArrowDown':
        this.selectedIndex = this.selectedIndex < itemCount - 1 ? this.selectedIndex + 1 : 0

        event.preventDefault()
        break
      case 'ArrowUp':
        this.selectedIndex = this.selectedIndex === 0 ? itemCount - 1 : this.selectedIndex - 1

        event.preventDefault()
        break
      case 'Enter':
        const selectedItem = this.navListItems.toArray()[this.selectedIndex]
        this.keyboardSelectedIndex.emit(this.selectedIndex)

        this.navListService.selectItem(selectedItem)
        break
      case 'Tab':
        break
    }

    const focusedItem = this.navListItems.toArray()[this.selectedIndex]
    this.navListService.focusItem(focusedItem)
  }
}
