import { Injectable, signal, WritableSignal } from '@angular/core'
import { NavListItemDirective } from './directives/nav-list-item/nav-list-item.directive'

interface NavListItem {
  itemId: string
  value: string
}

@Injectable()
export class NavListService {
  focusedItem: WritableSignal<NavListItem> = signal(null)
  selectedItem: WritableSignal<NavListItem> = signal(null)

  /**
   * Sets the focused item
   * @param {NavListItemDirective} item
   */
  focusItem(item: NavListItemDirective): void {
    if (!item) {
      return
    }

    this.focusedItem.set({ itemId: item.itemId, value: item.value })
  }

  /**
   * Removes the focused item
   */
  removeFocus(): void {
    this.focusedItem.set(null)
  }

  /**
   * Sets the selected item
   * @param {NavListItemDirective} item
   */
  selectItem(item: NavListItemDirective): void {
    if (!item) {
      return
    }

    this.selectedItem.set({ itemId: item.itemId, value: item.value })
  }

  /**
   * Removes the selected item
   */
  removeSelection(): void {
    this.selectedItem.set(null)
  }
}
