import { ConnectedPosition } from '@angular/cdk/overlay'

export const DEFAULT_POPUP_POSITION: ConnectedPosition = {
  /**
   * The default values positions the left top corner of the popup (overlay X: start/ overlayY: top)
   * under the left bottom corner of the origin element (origin X: start/ originY: bottom)
   *  ___________________________
   * |  Button (origin element)  |
   * |‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾|
   * |             Popup            |
   * |______________________________|
   */
  originX: 'start', // The point in the x-axis of the origin element in which the popup will be connected . Values: 'start' | 'center' | 'end'
  originY: 'bottom', // The point in the y-axis of the origin element in which the popup will be connected . Values: 'top' | 'center' | 'bottom'
  overlayX: 'start', // The point in the x-axis of the popup which will be connected with the origin. Values: 'start' | 'center' | 'end'
  overlayY: 'top', // The y-axis origin point on the overlay element where it will be connected to the origin. Values: 'top' | 'center' | 'bottom'
  offsetX: 0,
  offsetY: 0
}

export const ONBOARDING_POPUP_POSITION: ConnectedPosition = {
  ...DEFAULT_POPUP_POSITION,
  offsetX: -4,
  offsetY: 12
}

export const FRAMEWORK_MOBILE_POPUP_POSITION: ConnectedPosition = {
  ...DEFAULT_POPUP_POSITION,
  originX: 'end',
  overlayX: 'end',
  originY: 'top',
  overlayY: 'bottom',
  offsetY: 0
}

export const POPUP_MATCHING_INPUT_LABEL_POSITION: ConnectedPosition = {
  ...DEFAULT_POPUP_POSITION,
  originY: 'top'
}

export const QUICK_ACTIONS_POPUP_POSITION: ConnectedPosition = {
  ...DEFAULT_POPUP_POSITION,
  offsetY: 4
}

export const QUICK_ACTIONS_MOBILE_POPUP_POSITION: ConnectedPosition = {
  originX: 'end',
  overlayX: 'end',
  originY: 'top',
  overlayY: 'center',
  offsetX: 0,
  offsetY: 0
}
