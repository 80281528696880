import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { Company } from '@awork/features/company/models/company.model'
import { CompanyStore } from '@awork/features/company/state/company.store'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends EntitySignalQuery<Company> {
  static instance: CompanyQuery // Used to query the store in models (user, company, etc.)

  constructor(protected store: CompanyStore) {
    super(store)
  }

  /**
   * Selects company by id
   * @param {string} id
   */
  selectCompany(id: string): Observable<Company> {
    return this.selectEntity(id).pipe(map(company => this.mapEntity(company)))
  }

  /**
   * Selects companies by ids
   * @param {string[]} ids
   */
  selectCompanies(ids: string[]): Observable<Company[]> {
    return this.selectMany(ids).pipe(map(companies => this.mapEntities(companies)))
  }

  /**
   * Gets company by id
   * @param {string} id
   */
  getCompany(id: string): Company {
    const company = this.getEntity(id)
    return this.mapEntity(company)
  }

  /**
   * Selects all companies
   * @param {number} limit
   * @param {string} searchQuery
   */
  selectAllCompanies(limit?: number, searchQuery?: string): Observable<Company[]> {
    return this.selectAll({
      sortBy: (a: Company, b: Company) => {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1
        } else if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1
        }
        return 0
      },
      filterBy: company => this.searchFilter(company, searchQuery),
      limitTo: limit
    }).pipe(map(companies => this.mapEntities(companies)))
  }

  /**
   * Gets the count of companies
   * @param {string} searchQuery
   * @param {Company[]} filterCompanies
   * @returns {number}
   */
  getCompaniesCount(searchQuery?: string, filterCompanies?: Company[]): number {
    return this.getCount(
      company =>
        this.searchFilter(company, searchQuery) &&
        (!filterCompanies || !filterCompanies.filter(fc => !!fc).some(fc => fc.id === company.id))
    )
  }

  /**
   * Filter used for search by name
   * @param { Company } company
   * @param { string } searchQuery
   */
  private searchFilter(company: Company, searchQuery: string): boolean {
    company = new Company(company)
    return !searchQuery || (company.name && company.name.toLowerCase().includes(searchQuery.toLowerCase()))
  }
}
