<i *ngIf="showLeftIcon" class="aw-2-icn search-icon" (click)="input.focus()">search</i>

<input
  #input
  type="text"
  class="form-input"
  data-test="inline-search-field-input"
  awAutoFocus
  [autoFocus]="autoFocus"
  [ngClass]="{ 'has-icons': showLeftIcon, 'form-input--s': size === 's', 'aw-2-pr--xxxxl': input.value !== '' }"
  [attr.placeholder]="placeholder || null"
  [disabled]="disabled"
  (focus)="onFocus.emit($event)"
  (blur)="onBlur.emit($event)"
  (keydown.enter)="onEnterPressed.emit($event.target.value)"
  (keydown.esc)="input.blur(); onEscPressed.emit()"
  (input)="onInputChanged()"
/>

<div class="buttons">
  <aw-remove-button
    *ngIf="input.value !== ''"
    [size]="size"
    [clear]="true"
    [disabled]="disabled"
    (onClick)="clear()"
  />

  <aw-filter-button
    *ngIf="showFilterButton"
    [size]="size"
    [clear]="true"
    [active]="hasActiveFilters"
    (click)="filterButtonClicked($event)"
  >
    <ng-content />
  </aw-filter-button>
</div>
