import { FilePreviewComponent } from './../file-preview/file-preview.component'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { WithGlobals } from '../../../classes/with-globals'
import { UserQuery } from '@awork/features/user/state/user.query'
import { User } from '@awork/features/user/models/user.model'
import { FileService } from '@awork/_shared/services/file-service/file.service'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { Subscription, take } from 'rxjs'
import { PermissionsService } from '@awork/features/workspace/services/permission-service/permissions.service'
import { AccessLevels, Features } from '@awork/features/workspace/models/permissions.model'
import { Project } from '@awork/features/project/models/project.model'
import { Task } from '@awork/features/task/models/task.model'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FileSizePipe } from '../../../pipes/file-size/file-size.pipe'
import { FileIconComponent } from '@awork/_shared/components/file-icon/file-icon.component'
import { FilePreviewLightboxThumbnailComponent } from './file-preview-lightbox-thumbnail/file-preview-lightbox-thumbnail.component'
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common'
import { AccountService } from '@awork/_shared/services/account-service/account.service'
import { OverlayComponent } from '../../layout/overlay/overlay.component'
import { IconButtonComponent } from '../../icon-buttons/icon-button/icon-button.component'
import { Size } from '@awork/_shared/types/size'
import { DotsLoaderComponent } from '../../ui-help/dots-loader/dots-loader.component'
import { Color } from '@awork/_shared/types/color'
import { FabButtonComponent } from '../../icon-buttons/fab-button/fab-button.component'
import { SharedModalService } from '../../../services/shared-modal-service/shared-modal.service'
import replace from '@awork/_shared/functions/replace'
import { ConfirmModalOptions } from '../../../services/shared-modal-service/types'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

@AutoUnsubscribe()
@Component({
  selector: 'aw-file-preview-lightbox',
  templateUrl: './file-preview-lightbox.component.html',
  styleUrls: ['./file-preview-lightbox.component.scss'],
  standalone: true,
  imports: [
    OverlayComponent,
    NgIf,
    IconButtonComponent,
    FilePreviewLightboxThumbnailComponent,
    NgFor,
    FileIconComponent,
    NgClass,
    DatePipe,
    FileSizePipe,
    DotsLoaderComponent,
    forwardRef(() => FilePreviewComponent),
    FabButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePreviewLightboxComponent extends WithGlobals implements OnInit, AfterViewInit, OnDestroy {
  @Input() showAddAttachmentButton = false
  @Input() currentEntity: ValidFileEntities
  @Input() currentEntityName: ValidFileEntityTypes
  @Input() file: FileUpload

  @Output() updatedAttachments: EventEmitter<FileUpload[]> = new EventEmitter<FileUpload[]>()
  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('overlay') overlay: OverlayComponent
  @ViewChild('thumbnailsDetails') thumbnailsDetails: ElementRef
  @ViewChild('thumbnails') thumbnails: ElementRef
  @ViewChild('videoPreview') videoPreview: ElementRef

  sizes = Size
  colors = Color
  translations = q.translations.FilePreviewLightboxComponent

  currentUser: User
  subscriptions: Subscription[] = []

  showConfirmation = false
  sliderArray: FileUpload[]
  allAttachments: FileUpload[]
  fileId: string
  selectedIndex = 0

  canEdit: boolean

  videoLoading = true

  pdfPreview: {
    id: string
    file: SafeUrl
    isLoading: boolean
  }

  hasPDFPreviewError = false

  constructor(
    private userQuery: UserQuery,
    private fileService: FileService,
    public _domSanitizationService: DomSanitizer,
    private permissionsService: PermissionsService,
    private accountService: AccountService,
    private sharedModalService: SharedModalService,
    private cdr: ChangeDetectorRef
  ) {
    super()
    this.currentUser = this.userQuery.getCurrentUser()
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getFetchedFiles()
      this.show()
    })
  }

  ngOnDestroy(): void {}

  /**
   * Get Fetched files from service
   */
  private getFetchedFiles(): void {
    this.subscriptions.push(
      this.fileService.fetchedFiles.subscribe(files => {
        this.sliderArray = files

        this.initSlideArray()
        this.initCurrentFile()
      })
    )
  }

  /**
   * Inits Slide Array
   */
  private initSlideArray(): void {
    if (!this.sliderArray || !this.currentUser || !this.file) {
      return
    }

    this.canEdit = true

    const canEditProject = this.permissionsService.isAllowed(
      Features.ProjectMaster,
      AccessLevels.Manage,
      false,
      this.currentEntity as Project
    )
    const canEditTask = this.permissionsService.isAllowed(
      Features.ProjectPlanning,
      AccessLevels.Manage,
      false,
      (this.currentEntity as Task)?.project
    )

    if (this.file.entityType === 'projects') {
      this.canEdit = canEditProject
    } else if (this.file.entityType === 'tasks') {
      this.canEdit = canEditTask
    }

    this.sliderArray.forEach(attachment => {
      this.userQuery
        .selectUser(attachment.createdBy)
        .pipe(take(1))
        .subscribe(user => (attachment.username = user?.firstName || q.translations.common.unknown))
    })

    // store all attachments before filtering to be able to emit the whole array with the non image files
    this.allAttachments = this.sliderArray

    this.sliderArray = this.sliderArray?.filter(file => file.isPreviewSupported())
  }

  /**
   * Inits current file with slide array information
   */
  private initCurrentFile(): void {
    this.allAttachments = this.sliderArray

    const currentFile = this.sliderArray.find(file => file.id === this.file?.id)
    this.selectedIndex = this.sliderArray?.indexOf(currentFile)

    if (!currentFile) {
      return
    }

    if (currentFile.isVideo()) {
      setTimeout(() => this.videoPreview?.nativeElement.load())
    }

    if (currentFile.isPDFPreviewSupported()) {
      this.getFilePDFPreview(currentFile)
    }
  }

  /**
   * Fetches a PDF preview of a file
   * This is necessary to catch any error that might occur while fetching the PDF preview
   * @param {FileUpload} currentFile
   */
  private getFilePDFPreview(currentFile: FileUpload): void {
    this.fileService.fetchPDFPreview(currentFile.id).subscribe({
      next: blob => {
        this.accountService.getImageBase64FromBlob(blob).subscribe(res => {
          this.hasPDFPreviewError = false

          const blobUrl = URL.createObjectURL(blob)

          this.pdfPreview = {
            file: this._domSanitizationService.bypassSecurityTrustResourceUrl(blobUrl),
            id: currentFile.id,
            isLoading: false
          }

          this.cdr.markForCheck()
        })
      },
      error: () => {
        this.hasPDFPreviewError = true
        this.pdfPreview = null
        this.cdr.markForCheck()
      }
    })
  }

  /**
   * Listen to Keyboard inputs for Slid Navigation
   * @event {KeyboardEvent} - The keyboard event
   */
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight' && this.sliderArray && this.selectedIndex < this.sliderArray.length - 1) {
      this.next()
    } else if (event.key === 'ArrowLeft' && this.selectedIndex > 0) {
      this.prev()
    } else if (event.key === 'Escape') {
      this.hide()
    }
    this.adjustScrollPosition()
  }

  /**
   * Sets the selected item in view mode and adjusts the slider
   * @index {number} - The index of the selected thumbnail
   */
  protected selectThumbnail(index: number): void {
    this.selectedIndex = index
    this.file = this.sliderArray[this.selectedIndex]
    this.adjustScrollPosition()

    if (this.file?.isVideo()) {
      setTimeout(() => this.videoPreview?.nativeElement.load())
    } else if (this.file?.isPDFPreviewSupported()) {
      this.getFilePDFPreview(this.file)
    }
  }

  /**
   * Shows the overlay
   */
  private show(): void {
    this.overlay?.show?.()

    setTimeout(() => {
      this.adjustScrollPosition()
    })
  }

  /**
   * Hides the overlay
   */
  protected hide(): void {
    this.overlay.hide()
    this.hiding.emit()
  }

  /**
   * Go to Next slide
   */
  protected next(): void {
    if (this.selectedIndex === this.sliderArray?.length - 1) {
      return
    }

    this.selectedIndex++
    this.adjustScrollPosition()
    this.hasPDFPreviewError = false

    if (this.selectedIndex < this.sliderArray?.length) {
      this.file = this.sliderArray[this.selectedIndex]
    }

    if (this.file?.isVideo()) {
      setTimeout(() => this.videoPreview?.nativeElement.load())
    }

    if (this.file?.isPDFPreviewSupported()) {
      this.getFilePDFPreview(this.file)
    }
  }

  /**
   * Go to Previous slide
   */
  protected prev(): void {
    if (this.selectedIndex === 0) {
      return
    }

    this.hasPDFPreviewError = false

    this.selectedIndex--
    this.adjustScrollPosition()

    if (this.selectedIndex >= 0) {
      this.file = this.sliderArray[this.selectedIndex]
    }

    if (this.file?.isVideo()) {
      setTimeout(() => this.videoPreview?.nativeElement.load())
    } else if (this.file?.isPDFPreviewSupported()) {
      this.getFilePDFPreview(this.file)
    }
  }

  /**
   * Removes the attachment tile
   * @param {event} - The attachment to be removed
   */
  protected deleteAttachmentFile(event: FileUpload): void {
    const provider = this.file.externalProvider
    const filePreviewTranslations = q.translations.FilePreviewComponent

    const confirmOptions: ConfirmModalOptions = {
      title: provider ? filePreviewTranslations.removeFileTitle : filePreviewTranslations.deleteFileTitle,
      subtitle: provider
        ? replace(filePreviewTranslations.removeFileSubtitle, { fileName: this.file.name })
        : replace(filePreviewTranslations.deleteFileSubtitle, { fileName: this.file.name }),
      actionText: provider ? filePreviewTranslations.removeFileButtonText : q.translations.common.delete,
      actionColor: Color.Red,
      loadingObservable: null,
      delayHide: true,
      zIndex: 9999
    }

    this.sharedModalService.showConfirm(confirmOptions).confirm.subscribe(() => {
      if (this.selectedIndex !== 0) {
        this.selectedIndex--
        this.file = this.sliderArray.find((slide, index) => index === this.selectedIndex)
      }
      this.sliderArray = this.sliderArray.filter(slide => slide.id !== event.id)
      this.allAttachments = this.allAttachments.filter(slide => slide.id !== event.id)
      this.selectedIndex = 0

      if (this.file) {
        this.fileService.deleteEntityFile(this.file.entityType, this.file.entityId, event).subscribe()
      }

      this.updatedAttachments.emit(this.allAttachments)
      if (this.sliderArray && this.sliderArray.length <= 0) {
        this.hide()
      }
      this.cdr.markForCheck()
    })
  }

  /**
   * Add an attachment tile
   * @param {event} - The attachment to be removed
   */
  protected addNewFile(event: Event): void {
    const fileInput = event.target as HTMLInputElement

    let attachmentFile: FileUpload

    if (fileInput.files.length > 0) {
      Array.from(fileInput.files).forEach(file => {
        this.fileService.sendEntityFile(this.currentEntityName, this.currentEntity, file).subscribe(
          (fileUpload: FileUpload) => {
            attachmentFile = fileUpload
            attachmentFile.username = this.currentUser.fullName
          },
          err => {},
          () => {
            this.updatedAttachments.emit(this.allAttachments)
          }
        )
      })
    }
  }

  /**
   * Scroll between Thumbnails
   */
  private adjustScrollPosition(): void {
    // base values
    const thumbnailItemWidth = 68

    // calculate the width of the slider element
    if (
      this.thumbnailsDetails &&
      this.thumbnailsDetails.nativeElement &&
      this.thumbnails &&
      this.thumbnails.nativeElement
    ) {
      const thumbnailSliderWidth = this.thumbnailsDetails.nativeElement.offsetWidth
      const availableWidth = this.thumbnails.nativeElement.offsetWidth

      // move the position where the first item is in center
      let translateX = 0
      if (thumbnailSliderWidth > availableWidth) {
        translateX = availableWidth / 2 - thumbnailItemWidth / 2
      } else {
        translateX = thumbnailSliderWidth / 2 - thumbnailItemWidth / 2
      }

      // calculate position value by the index
      translateX = translateX - this.selectedIndex * thumbnailItemWidth
      this.thumbnailsDetails.nativeElement.style.transform = 'translateX(' + translateX + 'px)'
    }
  }

  /**
   * Gets the video mime type, for quicktime videos returns a different one
   * @param {FileUpload} file - The file to get the mime type of
   * @returns {string} - The files mime type
   */
  protected getVideoMimeType(file: FileUpload): string {
    if (file.mimeType === 'video/quicktime') {
      return 'video/mp4'
    }

    return file.mimeType
  }

  /**
   * Video is loaded
   */
  protected onVideoLoadedData(): void {
    this.videoLoading = false
  }

  /**
   * Video loading started
   */
  protected onVideoLoadStart(): void {
    this.videoLoading = true
  }
}
