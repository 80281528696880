import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common'
import { Size } from '@awork/_shared/types/size'
import { Project } from '@awork/features/project/models/project.model'
import { ProjectQuery } from '@awork/features/project/state/project.query'
import { AvatarPixelSizes, AvatarSize, SubAvatarPixelSizes } from '../../../../_shared/types/avatar-sizes'
import { CompanyQuery } from '@awork/features/company/state/company.query'
import { Router } from '@angular/router'
import { ProjectTemplate } from '@awork/features/project/models/project-template.model'

@Component({
  selector: 'aw-project-avatar',
  templateUrl: './project-avatar.component.html',
  styleUrls: ['./project-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, NgOptimizedImage]
})
export class ProjectAvatarComponent implements OnChanges {
  @Input({ required: true }) project: Project | ProjectTemplate
  @Input() size: AvatarSize = Size.m
  @Input() showText = true
  @Input() link: boolean = false
  @Input() multiline = false
  @Input() lines = 2
  @Input() forceLightTheme = false

  protected readonly avatarPixelSizes = AvatarPixelSizes
  protected readonly subAvatarPixelSizes = SubAvatarPixelSizes
  protected readonly sizes = Size

  imageUrl: string
  showSecondaryText = true
  imageHasError = false

  constructor(
    private projectQuery: ProjectQuery,
    private companyQuery: CompanyQuery,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project || changes.size) {
      this.setImage()
    }

    if (changes.size) {
      this.setShowSecondaryText()
    }
  }

  /**
   * Sets the image url with the project image or company image if available
   */
  private setImage(): void {
    const imageSize = AvatarPixelSizes[this.size]
    this.imageUrl = this.project?.getImage?.(imageSize, imageSize, false, this.projectQuery, this.companyQuery)
    this.imageHasError = !this.imageUrl
  }

  /**
   * Sets the showSecondaryText property
   */
  private setShowSecondaryText(): void {
    this.showSecondaryText = [Size.m, Size.l, Size.xl].includes(this.size)
  }

  /**
   * Handles the image error event
   */
  onImageError(): void {
    this.imageHasError = true
    this.cdr.markForCheck()
  }

  /**
   * Navigates to project details
   */
  linkClick(event: MouseEvent | TouchEvent) {
    if (this.link) {
      event.stopPropagation()
      this.router.navigate(['/projects', this.project.id])
    }
  }
}
