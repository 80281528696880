<aw-overlay
  #overlay
  [showCloseButton]="false"
  [ngClass]="classes"
  [blur]="false"
  (hiding)="hiding.emit()"
  (dragleave)="dragLeave($event)"
  (dragover)="dragOver($event)"
>
  <aw-entity-file-drop
    [entity]="entity"
    [entityType]="entityType"
    [fileService]="fileService"
    (onFilesDropped)="hide()"
  />
</aw-overlay>
