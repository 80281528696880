<figure
  *ngIf="status; else noStatus"
  [ngClass]="[
    'task-status',
    'task-status--' + status.color,
    'task-status--' + size,
    status.isWithoutColor ? 'task-status--without-color' : ''
  ]"
>
  <i
    *ngIf="!status.isWithoutColor"
    [ngClass]="[
      'aw-2-icn',
      'aw-2-icn--white',
      'aw-2-icn--' + size
    ]"
  >
    {{ status.iconName }}
  </i>
</figure>

<ng-template #noStatus>
  <figure
    [ngClass]="[
      'task-status',
      'task-status--empty',
      'task-status--' + size
    ]"
  >
  </figure>
</ng-template>
