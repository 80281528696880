import { PermissionsService } from '@awork/features/workspace/services/permission-service/permissions.service'
import { AccessLevels, Features } from '@awork/features/workspace/models/permissions.model'
import { Task } from '@awork/features/task/models/task.model'
import { Injector } from '@angular/core'
import { ProjectQuery } from '@awork/features/project/state/project.query'

type RouteConfig = (string | number | Object)[]

type NavigationTask = Pick<Task, 'id'> & Partial<Pick<Task, 'isPrivate' | 'projectId' | 'project'>>

function getProjectTaskNavigationRoute(task: NavigationTask): RouteConfig {
  return ['/projects', task.projectId, 'tasks', 'list', { outlets: { detail: [task.id] } }]
}

function getMyTaskNavigationRoute(task: NavigationTask): RouteConfig {
  return ['/tasks', 'filters', 'my-tasks', { outlets: { detail: [task.id] } }]
}

/**
 * Generates the right navigation route for the task detail page
 * Note: If the screen size is too small, the task detail page will open.
 * @param {Task} task
 * @param {PermissionsService} permissionsService
 */
export function getTaskDetailNavigationRoute(task: NavigationTask, injector: Injector): RouteConfig {
  const permissionsService = injector.get(PermissionsService)
  const projectQuery = injector.get(ProjectQuery)

  const project = task.projectId ? projectQuery.getProject(task.projectId) || task.project : null

  const hasProjectOwnTeamPermissions = permissionsService.isAllowProjectOwnTeamPermissions(project)

  const hasProjectAccess = permissionsService.isAllowed(Features.ProjectPlanning, AccessLevels.Read, false, project)

  if (task.projectId && hasProjectAccess && hasProjectOwnTeamPermissions) {
    return getProjectTaskNavigationRoute(task)
  }

  return getMyTaskNavigationRoute(task)
}
