import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core'
import { trigger, style, transition, animate } from '@angular/animations'
import { NgIf } from '@angular/common'
import { CloseButtonComponent } from '../../icon-buttons/close-button/close-button.component'
import { Size } from '@awork/_shared/types/size'

@Component({
  selector: 'aw-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  standalone: true,
  imports: [NgIf, CloseButtonComponent],
  animations: [
    trigger('overlay', [
      transition('* => void', [style({ opacity: '1' }), animate('.2s ease-in', style({ opacity: '0' }))]),
      transition('void => *', [style({ opacity: '0' }), animate('.4s ease-out', style({ opacity: '1' }))])
    ])
  ]
})
export class OverlayComponent implements OnInit {
  @Input() showCloseButton = true
  @Input() isBehindModals = false
  @Input() solid = false
  @Input() blur = true

  @Output() showing: EventEmitter<void> = new EventEmitter<void>()
  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()

  public visible = false

  protected readonly sizes = Size

  constructor(
    private browserService: BrowserService,
    public element: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  /**
   * Show the overlay component
   */
  show(): void {
    if (!this.visible) {
      this.visible = true
      this.showing.emit()
      this.browserService.setBodyScrolling(false)

      this.cdr.markForCheck()

      if (this.blur) {
        this.browserService.blurMainContent(true, false)
      }
    }
  }

  /**
   * Hide the overlay component
   */
  hide(): void {
    if (this.visible) {
      this.visible = false
      this.browserService.setBodyScrolling(true)
      if (this.blur) {
        this.browserService.blurMainContent(false, false)
      }
      this.hiding.emit()
    }
  }
}
