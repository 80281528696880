export const MyTaskId = 'my-tasks'

/**
 * Task View List model
 */
export class TaskViewList {
  id: string
  isShared: boolean
  filter: string
  filterSettings: string
  name: string
  order?: number
  collapsed = false
  shown = true
  createdOn?: string
  createdBy?: string
  updatedOn?: string
  updatedBy?: string
  taskIds?: string[]

  constructor(taskView: any) {
    Object.assign(this, taskView)
    if (!this.taskIds) {
      this.taskIds = []
    }
  }
}
