<svg viewBox="0 0 108 142" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" [class]="colorClass">
  <defs>
    <linearGradient x1="65.1305178%" y1="76.2092487%" x2="35.8450592%" y2="0%" id="linearGradient-1">
      <stop stop-color="#03003A" stop-opacity="0" offset="0%" />
      <stop stop-color="#00013D" offset="100%" />
    </linearGradient>
  </defs>
  <g id="Files" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Files---Elements" transform="translate(-740.000000, -1013.000000)">
      <g id="Group-11-Copy" transform="translate(740.000000, 1013.000000)">
        <path class="document" d="M65.417,-8.31335047e-14 L107.466,42.048 L107.466673,129.350007 C107.466673,135.811739 102.228404,141.050008 95.7666719,141.050008 L11.7000006,141.050008 C5.23826872,141.050008 2.31362464e-13,135.811739 2.21689346e-13,129.350007 L2.21689346e-13,11.7000006 C2.19121655e-13,5.23826872 5.23826872,-8.90519311e-14 11.7000006,-8.31335047e-14 L65.417,-8.31335047e-14 Z" id="Combined-Shape" fill="#2988FD" />
        <polygon id="Path-2" fill-opacity="0.3" fill="url(#linearGradient-1)" points="68.2499389 38.9745953 85.6607184 20.6998883 107.466673 42.4613659 107.466673 76.8727028" />
        <path class="fold" d="M85.126217,22.9639895 L106.83654,44.6743125 L106.83654,44.6743125 L46.8695944,44.6743125 L68.5799174,22.9639895 C73.1490518,18.394855 80.5570825,18.394855 85.126217,22.9639895 Z" id="Triangle-3" fill="#78B5FF" transform="translate(76.853067, 31.024312) rotate(-135.000000) translate(-76.853067, -31.024312) " />
      </g>
    </g>
  </g>
</svg>

  <span *ngIf="!extensionIcon" #fileExtension class="file-extension aw-txt--truncate">{{ extension ? '.' + extension : 'file' }}</span>
  <span *ngIf="extensionIcon" class="extension-icon">{{ extensionIcon }}</span>
