import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { NgClass } from '@angular/common'
import { WithGlobals } from '../../../classes/with-globals'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { PopupComponent } from '../../layout/popup/popup.component'
import { DEFAULT_POPUP_POSITION } from '../../layout/popup/positions'
import { ConnectedPosition } from '@angular/cdk/overlay'

@Component({
  selector: 'aw-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['../base-icon-button.scss', './filter-button.component.scss'],
  standalone: true,
  imports: [NgClass, TooltipDirective, PopupComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterButtonComponent extends WithGlobals implements OnChanges, OnInit {
  @Input() size: ButtonSize = Size.m
  @Input() clear: boolean
  @Input() tooltip: string = q.translations.common.filters
  @Input() active: boolean
  @Input() popupPosition: ConnectedPosition = DEFAULT_POPUP_POSITION

  @ViewChild('popup') popup: PopupComponent

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault()

    if (this.disabled) {
      event.stopPropagation()
      return
    }

    this.popup.show()
  }

  filterButtonClasses: string[]

  ngOnInit() {
    this.filterButtonClasses = this.getClasses()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.size && !changes.size.isFirstChange()) ||
      (changes.clear && !changes.clear.isFirstChange()) ||
      (changes.active && !changes.active.isFirstChange())
    ) {
      this.filterButtonClasses = this.getClasses()
    }
  }

  /**
   * Initializes all classes used to style the filter button.
   * @returns {string[]} The classes used to style the filter button.
   */
  getClasses(): string[] {
    return [
      `btn-icon--${this.size}`,
      ...(this.clear ? ['btn-icon--clear'] : []),
      ...(this.active ? ['btn-icon--active'] : [])
    ]
  }
}
