import { AfterViewInit, ChangeDetectorRef, Directive, effect, ElementRef, Input, Renderer2 } from '@angular/core'
import { NavListService } from '../../nav-list.service'
import { randomUuid } from '@awork/core/mocks_stubs/mock.helpers'

@Directive({
  selector: '[awNavListItem]',
  standalone: true
})
export class NavListItemDirective implements AfterViewInit {
  @Input() value: string

  itemId = randomUuid()

  constructor(
    private cdr: ChangeDetectorRef,
    private navListService: NavListService,
    public elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    effect(() => this.handleFocus())
  }

  ngAfterViewInit() {
    this.addClickListener()
  }

  /**
   * Add a listener to the keyboard events of the list container
   * @private
   */
  private addClickListener(): void {
    this.elementRef?.nativeElement?.addEventListener('click', (event: MouseEvent) => {
      this.navListService.selectItem(this)
    })
  }

  /**
   * Adds a class to the element if it is focused.
   * @param {NavListItemDirective} focusedItem
   * @private
   */
  private handleFocus(): void {
    if (!this.elementRef) {
      return
    }

    const focusedItem = this.navListService.focusedItem()

    if (focusedItem?.itemId === this.itemId) {
      this.renderer.addClass(this.elementRef.nativeElement, 'nav-list-item--focused')
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'nav-list-item--focused')
    }
  }
}
