<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayPositions]="[position]"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isVisible"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  [cdkConnectedOverlayBackdropClass]="'popup-backdrop'"
  [cdkConnectedOverlayDisableClose]="!hideOnEscape"
  (backdropClick)="handleClickOutside()"
  (overlayKeydown)="handleKeyDown($event)"
>
  <div
    class="popup"
    @popIn
    [class.with-background]="showBackground"
    [class.height-auto]="heightAuto"
    [ngClass]="[
      widthType === 'auto' ? 'popup--' + size : 'popup--block',
      'popup--' + variant
    ]"
    [style.width.px]="widthType !== 'auto' ? popupWidth : null"
    [style.height.px]="popupHeight"
  >
    <ng-content />
  </div>
</ng-template>
